export const environment:typeof import("./environment.dev").environment = {
    production: true,
    api: 'spesenapp-api-prod.webgate.biz/spesenapp',
    region:'eu-central-1',
    auth: {
      clientId:'3leuvido5i3s9or8u2brcm60kj',
      userPoolId:'eu-central-1_n7ya9o0uf',
      allowRegister:false,
      identityProviderSettings:{
          domainName:'wgc-spesenappprod.auth.eu-central-1.amazoncognito.com',
          redirectUri:'https://spesen.webgate.biz',
          providerName:'spesenappmainAAD',
          tokenMode:false
      }
    },
};